import { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../store";
import { getPhotosFromUnsplash } from "../../../_actions/publicActions";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Container, CircularProgress, Box, Typography } from "@mui/material";
import "./Gallery.scss";
import GalleryProfile from "./GalleryProfile";
import GalleryPhoto from "./GalleryPhoto";
import CircularLoader from "../../ui-components/CircularLoader/CircularLoader";

const Gallery = () => {
  const dispatch: AppDispatch = useDispatch();
  const publicState = useSelector((state: RootState) => state.publicInfo);
  const { listOfPhotos } = publicState;

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [hasTriggered, setHasTriggered] = useState(false);
  const [stopTriggering, setStopTriggering] = useState(false);
  const [loadingImages, setLoadingImages] = useState(new Set());
  const [selectedPhoto, setSelectedPhoto] = useState<null | {
    url: string;
    description: string;
  }>(null);

  const allPhotosRef = useRef<any[]>([]);

  useEffect(() => {
    const fetchPhotos = async () => {
      setLoading(true);
      await dispatch(getPhotosFromUnsplash(page));
      setLoading(false);
    };

    fetchPhotos();
  }, [dispatch, page]);

  useEffect(() => {
    if (listOfPhotos && listOfPhotos.length > 0) {
      console.log(listOfPhotos);
      const newPhotos = listOfPhotos.filter(
        (newPhoto: any) =>
          !allPhotosRef.current.some(
            (existingPhoto) => existingPhoto.id === newPhoto.id
          )
      );

      if (newPhotos.length > 0) {
        allPhotosRef.current = [...allPhotosRef.current, ...newPhotos];
      }
    }

    if (hasTriggered && listOfPhotos.length === 0) {
      setStopTriggering(true);
    }
  }, [listOfPhotos]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        !stopTriggering &&
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 1
      ) {
        setHasTriggered(true);
        setPage((prevPage) => prevPage + 1);
      }

      if (stopTriggering) {
        window.removeEventListener("scroll", handleScroll);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasTriggered, stopTriggering]);

  const handleImageLoad = (id: any) => {
    setLoadingImages((prev) => {
      const newSet = new Set(prev);
      newSet.delete(id);
      return newSet;
    });
  };

  const memoizedPhotos = useMemo(() => {
    return allPhotosRef.current;
  }, [allPhotosRef.current]);

  const handleImageClick = (photo: any) => {
    setSelectedPhoto({
      url: photo.urls.full, // Open full-size image in modal
      description: photo.description || "",
    });
  };

  const handleCloseModal = () => {
    setSelectedPhoto(null);
  };

  return (
    <Container maxWidth="xl">
      <GalleryProfile />
      {loading && memoizedPhotos.length === 0 ? (
        <CircularLoader customHeight={400} />
      ) : memoizedPhotos.length > 0 ? (
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry gutter={"5px"}>
            {memoizedPhotos.map((photo) => (
              <div
                className={`image-container ${
                  !loadingImages.has(photo.id) ? "loaded" : ""
                }`}
                key={photo.id}
                onClick={() => handleImageClick(photo)}
              >
                <img
                  className="zoom-image"
                  src={photo.urls.regular}
                  alt={photo.description || "Unsplash Photo"}
                  onLoad={() => handleImageLoad(photo.id)}
                  onError={() => handleImageLoad(photo.id)} // Handle error as well
                />
                <div
                  className="overlay"
                  style={{ height: photo.description ? 150 : "auto" }}
                >
                  <Typography sx={{ padding: "0 20%" }}>
                    {photo.description || ""}
                  </Typography>
                  <div className="overlay-text">
                    {Object.entries(photo.statistics)
                      .sort()
                      .map(([key, value]) => {
                        const statValue = value as { total: number };
                        return (
                          <Box key={key}>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: 700 }}
                              color={"primary"}
                            >
                              {statValue.total}
                            </Typography>
                            <Typography variant="subtitle2" color="secondary">
                              {key.charAt(0).toUpperCase() + key.slice(1)}
                            </Typography>
                          </Box>
                        );
                      })}
                  </div>
                </div>
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      ) : (
        <Typography variant="overline">No photos available</Typography>
      )}

      {selectedPhoto && (
        <GalleryPhoto
          open={!!selectedPhoto}
          handleClose={handleCloseModal}
          imageUrl={selectedPhoto.url}
          description={selectedPhoto.description}
        />
      )}
    </Container>
  );
};

export default Gallery;

import { Outlet, useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";

import Header from "../components/ui-components/Header/Header";
import Footer from "../components/ui-components/Footer/Footer";

import CssBaseline from "@mui/material/CssBaseline";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const PublicLayout = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Header />
      <CssBaseline />
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={200}>
          <main
            style={{
              minHeight: "100vh",
              marginTop: 100,
            }}
          >
            <Outlet />
          </main>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
    </>
  );
};

export default PublicLayout;

import * as React from "react";
import {
  AppBar,
  Container,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  Toolbar,
  Typography,
  useScrollTrigger,
  Slide,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

import WbSunnyIcon from "@mui/icons-material/WbSunny";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { useThemeContext } from "../../../ThemeContext";

interface Props {
  window?: () => Window;
  children?: React.ReactElement<any>;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children ?? <div />}
    </Slide>
  );
}

const drawerWidth = 240;
const navItems = [
  { navName: "Home", navLink: "/home" },
  { navName: "Blog", navLink: "/blog" },
  { navName: "About", navLink: "/about" },
  { navName: "Gallery", navLink: "/gallery" },
];

export default function Header(props: Props) {
  const { window } = props;
  const { toggleTheme, themeMode } = useThemeContext();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        JR
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.navLink} disablePadding>
            <ListItemButton
              component={Link}
              to={item.navLink}
              sx={{ textAlign: "center" }}
            >
              <ListItemText primary={item.navName} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar
          sx={{ background: themeMode === "dark" ? "#262626" : "#f0f2f5" }}
          position="fixed"
          component="nav"
        >
          <Container maxWidth="md">
            <Toolbar style={{ padding: 0, justifyContent: "space-between" }}>
              <IconButton
                color="secondary"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", sm: "block" },
                  background: "linear-gradient(to bottom, purple, red)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  fontFamily:
                    "'Poppins', sans-serif" /* You can also try 'Montserrat' or 'Nunito' */,
                  fontWeight: "bold",
                  // background: "linear-gradient(45deg, #EA8D8D, #A890FE)",
                  // WebkitBackgroundClip: "text",
                  // WebkitTextFillColor: "transparent",
                  // backgroundClip: "text",
                  // textFillColor: "transparent",
                  // fontWeight: "bolder",
                  // fontSize: "2.5rem",
                  // fontFamily: "'Trebuchet MS', sans-serif",
                  // fontStyle: "italic",
                }}
              >
                <Link to={"/home"}>JR</Link>
              </Typography>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                {navItems.map((item) => (
                  <Link key={item.navLink} to={item.navLink}>
                    <Button color="secondary">{item.navName}</Button>
                  </Link>
                ))}
              </Box>
              <IconButton onClick={toggleTheme}>
                {themeMode === "dark" ? (
                  <WbSunnyIcon sx={{ color: "#FDB813" }} />
                ) : (
                  <DarkModeIcon color="secondary" />
                )}
              </IconButton>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Home from "./components/User/Home";
import Blog from "./components/User/Blog";
import BlogDetail from "./components/User/BlogDetail/BlogDetail";
import About from "./components/User/About";

import AdminSignIn from "./components/Admin/AdminSignIn";
import AdminDashboard from "./components/Admin/AdminDashboard";
import AdminArticle from "./components/Admin/AdminArticle";
import AdminArticleDetail from "./components/Admin/AdminArticleDetail";
import AdminArticleAdd from "./components/Admin/AdminArticleAdd";
import AdminArticleEdit from "./components/Admin/AdminArticleEdit";
import AdminLayout from "./_layouts/AdminLayout";
import PublicLayout from "./_layouts/PublicLayout";
import Gallery from "./components/User/Gallery/Gallery";

const isAuthenticated = (): boolean => {
  return Boolean(localStorage.getItem("token")); // or your logic to check authentication
};
const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  return isAuthenticated() ? children : <Navigate to="/adminSignIn" />;
};

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route element={<PublicLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
        </Route>
        {/* Private Routes */}
        <Route path="/adminSignIn" element={<AdminSignIn />} />

        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <AdminLayout />
            </PrivateRoute>
          }
        >
          <Route path="dashboard" element={<AdminDashboard />} />
          <Route path="article" element={<AdminArticle />} />
          <Route path="article/:id" element={<AdminArticleDetail />} />
          <Route path="article/add" element={<AdminArticleAdd />} />
          <Route path="article/edit/:id" element={<AdminArticleEdit />} />
        </Route>

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default App;

import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store";
import { addArticle, clearArticleData } from "../../_actions/articleActions";
import { convertToRaw, EditorState } from "draft-js";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";

import DraftEditor from "../DraftEditor/DraftEditor";
import Notify from "../ui-components/Notify/Notify";

import { useNavigate } from "react-router-dom";

const AdminArticleAdd = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const articleState = useSelector((state: RootState) => state.articles);
  const { articleAdded } = articleState;

  const [alertDisplay, setAlertDisplay] = React.useState<{
    open: boolean;
    alertColor?: "success" | "error" | "info" | "warning";
    alertMsg?: string;
  }>({
    open: false,
  });

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    icon: "",
    repositoryUrl: "",
    isPublished: false,
    enableFeedback: false,
    articleContent: [
      {
        articleStep: EditorState.createEmpty(),
        articleScreenshots: [] as File[],
      },
    ],
  });

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleEditorChange = (index: number, editorState: EditorState) => {
    const newArticleContent = [...formData.articleContent];
    newArticleContent[index].articleStep = editorState;
    setFormData((prevData) => ({
      ...prevData,
      articleContent: newArticleContent,
    }));
  };

  const handleAddStep = () => {
    setFormData((prevData) => ({
      ...prevData,
      articleContent: [
        ...prevData.articleContent,
        {
          articleStep: EditorState.createEmpty(),
          articleScreenshots: [] as File[],
        },
      ],
    }));
  };

  const handleRemoveStep = (index: number) => {
    const newArticleContent = formData.articleContent.filter(
      (_, i) => i !== index
    );
    setFormData((prevData) => ({
      ...prevData,
      articleContent: newArticleContent,
    }));
  };

  const handleAddScreenshot = (index: number, file: File) => {
    if (file.size > 2 * 1024 * 1024) {
      alert("File size should be less than 2 MB.");
      return;
    }
    setFormData((prevData) => {
      const updatedContent = [...prevData.articleContent];
      updatedContent[index].articleScreenshots.push(file);
      return {
        ...prevData,
        articleContent: updatedContent,
      };
    });
  };

  const handleRemoveScreenshot = (
    stepIndex: number,
    screenshotIndex: number
  ) => {
    setFormData((prevData) => {
      const updatedContent = [...prevData.articleContent];
      updatedContent[stepIndex].articleScreenshots.splice(screenshotIndex, 1);
      return {
        ...prevData,
        articleContent: updatedContent,
      };
    });
  };

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formattedSteps = await Promise.all(
      formData.articleContent.map(async (step) => ({
        articleStep: convertToRaw(step.articleStep.getCurrentContent()),
        articleScreenshots: await Promise.all(
          step.articleScreenshots.map(fileToBase64)
        ),
      }))
    );

    const articleData = {
      title: formData.title,
      description: formData.description,
      icon: formData.icon,
      repositoryUrl: formData.repositoryUrl,
      isPublished: formData.isPublished,
      enableFeedback: formData.enableFeedback,
      articleContent: formattedSteps,
    };

    await dispatch(addArticle(articleData));
  };

  useEffect(() => {
    console.log("=== articleAdded ===");
    console.log(articleAdded);
    if (!!articleAdded) {
      const { status, data } = articleAdded;
      setAlertDisplay({
        open: true,
        alertColor:
          status === 201 ? "success" : status === 404 ? "warning" : "error",
        alertMsg: data.msg,
      });
      setTimeout(() => {
        dispatch(clearArticleData());
        setAlertDisplay({
          open: false,
        });
        navigate("/admin/article");
      }, 3000);
      return () => {
        dispatch(clearArticleData());
      };
    }
  }, [dispatch, articleAdded]);

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" align="left" gutterBottom>
        Add New Article
      </Typography>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
        onSubmit={handleSubmit}
      >
        <TextField
          id="title"
          label="Title"
          variant="outlined"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          required
          sx={{ width: "50%" }}
        />
        <TextField
          id="description"
          label="Description"
          variant="outlined"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          multiline
          rows={4}
          required
        />
        <TextField
          id="icon"
          label="Icon URL"
          variant="outlined"
          name="icon"
          value={formData.icon}
          onChange={handleInputChange}
          sx={{ width: "50%" }}
        />
        <TextField
          id="repositoryUrl"
          label="Repository URL"
          variant="outlined"
          name="repositoryUrl"
          value={formData.repositoryUrl}
          onChange={handleInputChange}
          sx={{ width: "50%" }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={formData.isPublished}
              onChange={handleSwitchChange}
              name="isPublished"
              color="primary"
            />
          }
          label="Publish Article"
        />
        <FormControlLabel
          control={
            <Switch
              checked={formData.enableFeedback}
              onChange={handleSwitchChange}
              name="enableFeedback"
              color="primary"
            />
          }
          label="Enable Feedback"
        />

        {formData.articleContent.map((step, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <DraftEditor
              editorState={step.articleStep}
              setEditorState={(editorState) =>
                handleEditorChange(index, editorState)
              }
            />
            <input
              type="file"
              accept="image/jpeg, image/png"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  handleAddScreenshot(index, e.target.files[0]);
                }
              }}
              style={{ display: "block", marginTop: "8px" }}
            />
            {step.articleScreenshots.map((file, fileIndex) => (
              <Box
                key={fileIndex}
                sx={{ display: "flex", alignItems: "center", mt: 1 }}
              >
                <img
                  src={URL.createObjectURL(file)}
                  alt={`Screenshot ${fileIndex + 1}`}
                  style={{ width: "100px", marginRight: "8px" }}
                />
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleRemoveScreenshot(index, fileIndex)}
                >
                  Remove Screenshot
                </Button>
              </Box>
            ))}
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleRemoveStep(index)}
              sx={{ mt: 1.5 }}
              size="small"
            >
              Remove Step
            </Button>
          </Box>
        ))}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleAddStep}
          sx={{ mb: 2, width: 200 }}
          size="small"
        >
          Add Step
        </Button>
        <Box sx={{ alignSelf: "end" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            sx={{ width: 300 }}
            endIcon={<DoneOutlineIcon />}
          >
            SAVE AND SUBMIT
          </Button>
        </Box>
      </Box>
      <Notify alertDisplay={alertDisplay} setAlertDisplay={setAlertDisplay} />
    </Container>
  );
};

export default AdminArticleAdd;

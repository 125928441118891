import { PublicAction } from "../_types";

interface PublicState {
  listOfPhotos?: any;
  unsplashProfile?: any;
  likesData?: any;
  likesUpdated?: any;
}

const initialState: PublicState = {
  listOfPhotos: [],
  unsplashProfile: null,
  likesData: null,
  likesUpdated: null,
};

const publicReducer = (
  state = initialState,
  action: PublicAction
): PublicState => {
  switch (action.type) {
    case "FETCH_PHOTOS":
      return {
        ...state,
        listOfPhotos: action.payload,
      };
    case "GET_UNSPLASH_PROFILE":
      return {
        ...state,
        unsplashProfile: action.payload,
      };
    case "GET_LIKES":
      return {
        ...state,
        likesData: action.payload,
      };
    case "UPDATE_LIKES":
      return {
        ...state,
        likesUpdated: action.payload,
      };
    default:
      return state;
  }
};

export default publicReducer;

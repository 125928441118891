import { Box, Typography } from "@mui/material";
import React from "react";

interface EmptyDataProps {
  fileName: any;
  title: string;
  customHeight: string;
  imgHeight?: number;
}

const EmptyData: React.FC<EmptyDataProps> = ({
  fileName,
  title,
  customHeight,
  imgHeight = 250,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        alignContent: "center",
        height: customHeight,
      }}
    >
      <img src={fileName} height={imgHeight} />
      <Typography sx={{ marginTop: 4 }} variant="button">
        {title}
      </Typography>
    </Box>
  );
};

export default EmptyData;

import {
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import moment from "moment";
import Masonry from "@mui/lab/Masonry";
import EmptyData from "../ui-components/EmptyData/EmptyData";

import programmingSvg from "../../assets/illustrations/programming.svg";
import CircularLoader from "../ui-components/CircularLoader/CircularLoader";

interface Article {
  _id: number;
  title: string;
  description: string;
  icon: string;
  createdDate: string;
  modifiedDate: string;
}

interface ProjectsProps {
  listOfArticles: Article[];
  handleOpenArticle: (id: number) => void;
  loading: boolean;
  error: any;
}

const DescriptionTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(1.5),
  display: "-webkit-box",
  WebkitLineClamp: 5,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
}));

const Projects: React.FC<ProjectsProps> = ({
  listOfArticles,
  handleOpenArticle,
  loading,
  error,
}) => {
  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isXl = useMediaQuery(theme.breakpoints.down("xl"));

  const getColumns = () => {
    if (isXs || isSm) return 1;
    if (isMd || isLg || isXl) return 3;
  };

  return (
    <>
      <Typography variant="h5">Projects</Typography>
      {loading && <CircularLoader customHeight={250} />}

      {error && (
        <EmptyData
          fileName={programmingSvg}
          title={"No Projects are available"}
          customHeight="auto"
        />
      )}
      {listOfArticles && listOfArticles.length > 0 && (
        <Masonry columns={getColumns()} spacing={2} sx={{ marginTop: 1 }}>
          {listOfArticles.map((item) => (
            <Card key={item._id}>
              <CardContent>
                <Typography
                  gutterBottom
                  sx={{ color: "text.secondary", fontSize: 14 }}
                >
                  {moment(item.createdDate).format("LLL")}
                </Typography>
                <Typography variant="h5" component="div">
                  {item.title}
                </Typography>
                <DescriptionTypo>{item.description}</DescriptionTypo>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => handleOpenArticle(item._id)}
                >
                  Article
                </Button>
              </CardActions>
            </Card>
          ))}
        </Masonry>
      )}
    </>
  );
};

export default Projects;

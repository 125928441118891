import { ArticleAction } from "../_types";

interface ArticleState {
  articleAdded?: any;
  articleEdited?: any;
  articleDeleted?: any;
  articleDetails?: any;
  articleList?: any[];
  loading: boolean; // New loading state
  error?: string | null;
}

const initialState: ArticleState = {
  articleAdded: null,
  articleEdited: null,
  articleDeleted: null,
  articleDetails: null,
  articleList: [],
  loading: false, // Initially not loading
  error: null,
};

const articleReducer = (
  state = initialState,
  action: ArticleAction
): ArticleState => {
  switch (action.type) {
    case "GET_ARTICLE_BY_ID":
      if (action.status === 'loading') {
        return {
          ...state,
          loading: true,
          error: null,
        };
      } else if (action.status === 'success') {
        return {
          ...state,
          loading: false,
          articleDetails: action.payload,
          error: null,
        };
      } else if (action.status === 'failure') {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }
      return state;

    case "ADD_ARTICLE":
      return {
        ...state,
        articleAdded: action.payload,
      };
    case "EDIT_ARTICLE":
      return {
        ...state,
        articleEdited: action.payload,
      };
    case "DELETE_ARTICLE":
      return {
        ...state,
        articleDeleted: action.payload,
      };
    case "LIST_OF_ARTICLES":
      if (action.status === 'loading') {
        return {
          ...state,
          loading: true,
          error: null,
        };
      } else if (action.status === 'success') {
        return {
          ...state,
          loading: false,
          articleList: action.payload,
          error: null,
        };
      } else if (action.status === 'failure') {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }
      return state;
    case "CLEAR_ARTICLE_DATA":
      return {
        ...state,
        articleAdded: null,
        articleEdited: null,
      };
    default:
      return state;
  }
};

export default articleReducer;

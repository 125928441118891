import axios from "axios";
import api from '../api';
import { Dispatch } from "redux";
import { FETCH_PHOTOS, GET_UNSPLASH_PROFILE, GET_LIKES, UPDATE_LIKES, PublicAction } from "../_types";

// Base API setup for Unsplash and Likes API
const api2 = axios.create({
  baseURL: "https://api.unsplash.com", // For Unsplash API
});

const clientId = "xfNUw7vAid_2KoZ6xjhm3b4LEJIasCoZh5LgxJWd1PM";

/**
 * Fetch photos from Unsplash for a specific user with pagination.
 * @param page - The page number to fetch.
 */
export const getPhotosFromUnsplash =
  (page: number) => async (dispatch: Dispatch<PublicAction>) => {
    try {
      const response = await api2.get(
        `/users/jayakarreddy96/photos?stats=true&per_page=10&page=${page}&client_id=${clientId}`
      );

      dispatch({
        type: FETCH_PHOTOS,
        status: "success",
        payload: response.data,
      });

      return { payload: response.data };
    } catch (error: any) {
      dispatch({
        type: FETCH_PHOTOS,
        status: "failure",
        payload: error.message || "An unknown error occurred",
      });

      return { payload: [] };
    }
  };

  export const getProfileFromUnsplash =
  () => async (dispatch: Dispatch<PublicAction>) => {
    try {
      const response = await api2.get(`/users/jayakarreddy96?client_id=${clientId}`
      );

      dispatch({
        type: GET_UNSPLASH_PROFILE,
        status: "success",
        payload: response.data,
      });

      return { payload: response.data };
    } catch (error: any) {
      dispatch({
        type: GET_UNSPLASH_PROFILE,
        status: "failure",
        payload: error.message || "An unknown error occurred",
      });

      return { payload: null };
    }
  };

export const getLikesByArticleID =
  (id: string) => async (dispatch: Dispatch<PublicAction>) => {
    try {
      const response = await api.get(`/article/likes/${id}`);
      dispatch({
        type: GET_LIKES,
        status: "success",
        payload: response.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_LIKES,
        status: "failure",
        payload: error.message || "An unknown error occurred",
      });
    }
  };


export const updateLikes =
  (id: string, likesCount: number) =>
  async (dispatch: Dispatch<PublicAction>) => {
    try {
      const response = await api.put(`/article/likes/${id}`, {
        likesCount,
      });
      dispatch({
        type: UPDATE_LIKES,
        status: "success",
        payload: response.data,
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_LIKES,
        status: "failure",
        payload: error.message || "An unknown error occurred",
      });
    }
  };

import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  Container,
  IconButton,
  Chip,
} from "@mui/material";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import GitHubIcon from "@mui/icons-material/GitHub";
import CommentIcon from "@mui/icons-material/Comment";
import CommentsDisabledIcon from "@mui/icons-material/CommentsDisabled";

import { adminGetArticles, deleteArticle } from "../../_actions/articleActions";
import { useNavigate } from "react-router-dom";
import Notify from "../ui-components/Notify/Notify";

import moment from "moment";

interface Article {
  _id: number;
  title: string;
  description: string;
  isPublished: Boolean;
  enableFeedback: Boolean;
  repositoryUrl: String;
  icon: String;
  createdDate: string;
  modifiedDate: string;
}

const AdminArticle = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const articleState = useSelector((state: RootState) => state.articles);
  const { articleList, articleDeleted } = articleState;

  const columns: GridColDef<(typeof rows)[number]>[] = [
    { field: "_id", headerName: "ID", width: 150 },
    {
      field: "title",
      headerName: "Title",
      width: 250,
    },
    // {
    //   field: "description",
    //   headerName: "Description",
    //   width: 150,
    // },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 120,
      renderCell: (params) => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption">
            {moment(params.row.createdDate).format("MMM Do YY")}
          </Typography>
          <Typography variant="caption">
            {moment(params.row.createdDate).format("LT")}
          </Typography>
        </Box>
      ),
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      width: 120,
      renderCell: (params) => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption">
            {moment(params.row.modifiedDate).format("MMM Do YY")}
          </Typography>
          <Typography variant="caption">
            {moment(params.row.modifiedDate).format("LT")}
          </Typography>
        </Box>
      ),
    },
    {
      field: "isPublished",
      headerName: "Published",
      width: 90,
      renderCell: (params) =>
        params.row.isPublished ? (
          <Chip
            color="success"
            size="small"
            icon={<CheckCircleIcon />}
            label="Yes"
          />
        ) : (
          <Chip
            color="error"
            size="small"
            icon={<UnpublishedIcon />}
            label="No"
          />
        ),
    },
    {
      field: "repositoryUrl",
      headerName: "Src URL",
      width: 90,
      renderCell: (params) =>
        params.row.repositoryUrl ? (
          <Chip color="info" size="small" icon={<GitHubIcon />} label="OK" />
        ) : (
          "-"
        ),
    },
    {
      field: "enableFeedback",
      headerName: "Feedback",
      width: 90,
      renderCell: (params) =>
        params.row.enableFeedback ? (
          <IconButton color="info">
            <CommentIcon />
          </IconButton>
        ) : (
          <IconButton color="warning">
            <CommentsDisabledIcon color="warning" />
          </IconButton>
        ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 80,
      renderCell: (params) => (
        <IconButton onClick={() => handleEdit(params.row._id)} color="primary">
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 80,
      renderCell: (params) => (
        <IconButton onClick={() => handleDelete(params.row._id)} color="error">
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const [rows, setRows] = useState<Article[]>([]);

  const [alertDisplay, setAlertDisplay] = React.useState<{
    open: boolean;
    alertColor?: "success" | "error" | "info" | "warning";
    alertMsg?: string;
  }>({
    open: false,
  });

  const handleEdit = (id: any) => {
    navigate(`/admin/article/edit/${id}`);
  };

  const handleDelete = (id: any) => {
    dispatch(deleteArticle(id));
  };

  useEffect(() => {
    dispatch(adminGetArticles());
    if (!!articleDeleted) {
      const { status, msg, id } = articleDeleted;
      setAlertDisplay({
        open: true,
        alertColor:
          status === 200 ? "success" : status === 404 ? "warning" : "error",
        alertMsg: msg,
      });
    }
  }, [dispatch, articleDeleted]);

  useEffect(() => {
    if (articleList) {
      setRows(articleList);
    }
  }, [articleList]);

  const { open, alertColor, alertMsg } = alertDisplay;
  return (
    <Container maxWidth="xl">
      <Typography variant="h4" mb={2}>
        List of Articles
      </Typography>
      {articleList ? (
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row._id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          pageSizeOptions={[8]}
          disableRowSelectionOnClick={true}
          // disableDensitySelector={true}
          // disableColumnSelector={true}
          // disableMultipleRowSelection={true}
        />
      ) : (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      )}
      <Notify alertDisplay={alertDisplay} setAlertDisplay={setAlertDisplay} />
    </Container>
  );
};

export default AdminArticle;

import { combineReducers } from '@reduxjs/toolkit';
import articleReducer from './articleReducer';
import adminReducer from './adminReducer';
import publicReducer from './publicReducer';

const rootReducer = combineReducers({
  articles: articleReducer,
  admin: adminReducer,
  publicInfo: publicReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
import { Box, CircularProgress } from "@mui/material";

interface CircularLoaderProps {
  customHeight: number;
}

const CircularLoader: React.FC<CircularLoaderProps> = ({ customHeight }) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: customHeight,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default CircularLoader;

import { ADMIN_SIGN_IN, AdminAction } from '../_types';

interface AdminState {
  adminLoggedIn?: any
}

const initialState: AdminState = {
  adminLoggedIn: null,
};

const adminReducer = (
  state = initialState,
  action: AdminAction
): AdminState => {
  switch (action.type) {
    case "ADMIN_SIGN_IN":
      return {
        ...state,
        adminLoggedIn: action.payload,
      };
    default:
      return state;
  }
};

export default adminReducer;

import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Box,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface GalleryPhotoProps {
  open: boolean;
  handleClose: () => void;
  imageUrl: string;
  description: string;
}

const GalleryPhoto: React.FC<GalleryPhotoProps> = ({
  open,
  handleClose,
  imageUrl,
  description,
}) => {
  const [loading, setLoading] = useState(true); // Manage loading state

  const handleImageLoad = () => {
    setLoading(false); // Set loading to false when image is fully loaded
  };

  const handleImageError = () => {
    setLoading(false); // Set loading to false if image fails to load
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          margin: 0,
          maxWidth: "100%",
          width: "auto",
          maxHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      {/* Backdrop with CircularProgress for loading */}
      <Backdrop
        open={loading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: "#fff",
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        size="small"
        sx={{
          position: "absolute",
          right: 2,
          top: 2,
          color: (theme) => theme.palette.primary.contrastText,
          background: (theme) => theme.palette.secondary.light,
          "&:hover": {
            color: (theme) => theme.palette.primary.main,
            background: (theme) => theme.palette.secondary.contrastText,
          },
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent
        sx={{
          padding: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Box
          component="img"
          src={imageUrl}
          alt={description || "Unsplash Photo"}
          onLoad={handleImageLoad} // Trigger when image loads successfully
          onError={handleImageError} // Trigger if image fails to load
          sx={{
            maxWidth: "100%",
            maxHeight: "100vh",
            width: "auto",
            height: "96vh",
            objectFit: "contain", // Keep aspect ratio and prevent cropping
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default GalleryPhoto;

import axios from "axios";

// Create an axios instance
const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://project-j-backend.onrender.com/api" // Production API URL
      : "http://localhost:5000/api", // Development API URL
});

// Add a request interceptor to include the token
api.interceptors.request.use(
  (config) => {
    if (config.url && config.url.startsWith("/admin")) {
      const token = localStorage.getItem("token"); // or use Redux store
      if (!token) {
        // If no token is found and the request is to a protected route
        window.location.href = "/adminSignIn"; // Redirect to the login page
        return Promise.reject("No token found"); // Reject the request to prevent it from being sent
      }

      if (token) {
        config.headers["x-auth-token"] = token;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle errors globally
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle global error here
    if (error.response.status === 401 || error.response.status === 403) {
      return (window.location.href = "/adminSignIn");
    }
    return Promise.reject(error);
  }
);

export default api;

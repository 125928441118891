import { AppBar, Typography, Container, Box, IconButton } from "@mui/material";
import {
  GitHub as GitHubIcon,
  Instagram as InstagramIcon,
  Facebook as FacebookIcon,
  WhatsApp as WhatsAppIcon,
} from "@mui/icons-material";

const Footer = () => {
  return (
    <AppBar
      position="static"
      color="transparent"
      sx={{
        top: "auto",
        bottom: 0,
        marginTop: 10,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          height: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <IconButton
            aria-label="github"
            sx={{
              color: (theme) =>
                theme.palette.mode === "dark" ? "#ffffff" : "#171515",
            }}
            href="https://github.com/Jayakarreddy"
            target="_blank"
          >
            <GitHubIcon />
          </IconButton>

          <IconButton
            aria-label="instagram"
            sx={{
              color: "#d62976",
            }}
            href="https://www.instagram.com/jayakar_reddy_marthala/"
            target="_blank"
          >
            <InstagramIcon />
          </IconButton>

          <IconButton
            aria-label="facebook"
            sx={{ color: "#1877F2" }}
            href="https://www.facebook.com/jayakarbunny.jayakar/"
            target="_blank"
          >
            <FacebookIcon />
          </IconButton>

          <IconButton
            aria-label="whatsapp"
            sx={{ color: "#25d366" }}
            href="https://www.facebook.com/jayakarbunny.jayakar/"
            target="_blank"
          >
            <WhatsAppIcon />
          </IconButton>
        </Box>

        <Typography variant="caption">
          copyright&#169;jayakarreddy.com
        </Typography>
      </Container>
    </AppBar>
  );
};

export default Footer;

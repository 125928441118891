import React, { useEffect, useState } from "react";
import { Container, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";

import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store";

import { adminGetArticles } from "../../_actions/articleActions";

interface Article {
  _id: number;
  title: string;
  description: string;
  isPublished: Boolean;
  enableFeedback: Boolean;
  repositoryUrl: String;
  icon: String;
  createdDate: string;
  modifiedDate: string;
  likesCount: number;
}

const pData = [50, 100, 150, 200, 250];
const likes = [0, 131, 24, 0, 0, 0, 0, 0];

export default function AdminDashboard() {
  const dispatch: AppDispatch = useDispatch();

  const articleState = useSelector((state: RootState) => state.articles);
  const { articleList } = articleState;
  const [titles, setTitles] = useState<Article[]>([]);
  // const [likes, setLikes] = useState<Article[]>([]);

  useEffect(() => {
    dispatch(adminGetArticles());
  }, [dispatch]);

  useEffect(() => {
    if (articleList) {
      console.log(articleList);
      setTitles(articleList.map((x) => x.title));
      const likes = articleList.map((x) => x.likesCount || 0);
      // setLikes(likes);
    }
  }, [articleList]);

  // console.log(likes);

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" mb={2}>
        Dashboard
      </Typography>
      {titles && titles.length > 0 && (
        <BarChart
          height={300}
          series={[
            { data: pData, label: "pv", id: "pvId", stack: "total" },
            { data: likes, label: "Likes", id: "uvId", stack: "total" },
          ]}
          xAxis={[
            {
              data: titles,
              scaleType: "band",
              tickLabelStyle: {
                display: "none",
              },
            },
          ]}
        />
      )}
    </Container>
  );
}

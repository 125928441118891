import api from '../api';
import { Dispatch } from 'redux';
import { ADMIN_SIGN_IN, AdminAction } from '../_types';
import { AxiosError } from 'axios';

export const adminLogin = (loginData: { email: string; password: string }) => async (dispatch: Dispatch<AdminAction>) => {
  try {
    localStorage.clear()
    const res = await api.post('/login', loginData);
    localStorage.setItem('token', res.data.token);
    dispatch({
      type: ADMIN_SIGN_IN,
      payload: res.data
    });
  } catch (error) {
    const axiosError = error as AxiosError; 
    dispatch({
      type: ADMIN_SIGN_IN,
      payload: axiosError.response ,
    });
    
  }
};

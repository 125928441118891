import React, { useEffect, useState, ChangeEvent, FormEvent } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState, AppDispatch } from "../../store";
import {
  adminGetArticleByID,
  editArticle,
  clearArticleData,
} from "../../_actions/articleActions";
import DraftEditor from "../DraftEditor/DraftEditor";
import {
  convertToRaw,
  EditorState,
  convertFromRaw,
  RawDraftContentState,
} from "draft-js";

import { useNavigate } from "react-router-dom";
import Notify from "../ui-components/Notify/Notify";

// Define TypeScript types for the form data
interface ArticleContent {
  articleStep: EditorState;
  articleScreenshots: string[]; // explicitly define this as string[]
}

interface FormData {
  title: string;
  description: string;
  icon: string; // Added field for icon
  isPublished: boolean; // Added field for publish status
  repositoryUrl: string; // Added field for repository URL
  enableFeedback: boolean; // Added field for feedback option
  articleContent: ArticleContent[];
}

const defaultContent: RawDraftContentState = {
  blocks: [
    {
      key: "1",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
};

const AdminArticleEdit = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const articleState = useSelector((state: RootState) => state.articles);
  const { articleDetails, articleEdited } = articleState;
  const [alertDisplay, setAlertDisplay] = React.useState<{
    open: boolean;
    alertColor?: "success" | "error" | "info" | "warning";
    alertMsg?: string;
  }>({
    open: false,
  });

  const [formData, setFormData] = useState<FormData>({
    title: "",
    description: "",
    icon: "",
    isPublished: false,
    repositoryUrl: "",
    enableFeedback: false,
    articleContent: [
      { articleStep: EditorState.createEmpty(), articleScreenshots: [] },
    ],
  });

  useEffect(() => {
    if (id) {
      dispatch(adminGetArticleByID(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (articleDetails && articleDetails.articleContent) {
      try {
        const steps = articleDetails.articleContent.map((step: any) => {
          const rawContent = step.articleStep
            ? {
                blocks: step.articleStep.blocks || [],
                entityMap: step.articleStep.entityMap || {},
              }
            : defaultContent;

          if (rawContent.blocks && Array.isArray(rawContent.blocks)) {
            const content = convertFromRaw(rawContent);
            return {
              articleStep: EditorState.createWithContent(content),
              articleScreenshots: step.articleScreenshots || [],
            };
          } else {
            throw new Error("Invalid article content format");
          }
        });

        setFormData({
          title: articleDetails.title || "",
          description: articleDetails.description || "",
          icon: articleDetails.icon || "",
          isPublished: articleDetails.isPublished || false,
          repositoryUrl: articleDetails.repositoryUrl || "",
          enableFeedback: articleDetails.enableFeedback || false,
          articleContent: steps,
        });
      } catch (error) {
        console.error("Failed to convert article content:", error);
        setFormData({
          title: articleDetails.title || "",
          description: articleDetails.description || "",
          icon: "",
          isPublished: false,
          repositoryUrl: "",
          enableFeedback: false,
          articleContent: [
            {
              articleStep: EditorState.createWithContent(
                convertFromRaw(defaultContent)
              ),
              articleScreenshots: [],
            },
          ],
        });
      }
    }
  }, [articleDetails]);

  useEffect(() => {
    console.log("=== articleEdited ===");
    console.log(articleEdited);
    if (!!articleEdited) {
      const { status, data } = articleEdited;
      setAlertDisplay({
        open: true,
        alertColor:
          status === 201 ? "success" : status === 404 ? "warning" : "error",
        alertMsg: data.msg,
      });
      setTimeout(() => {
        dispatch(clearArticleData());
        navigate("/admin/article");
      }, 3000);
      return () => {
        dispatch(clearArticleData());
      };
    }
  }, [dispatch, articleEdited]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleEditorChange = (index: number, editorState: EditorState) => {
    setFormData((prevData) => {
      const updatedContent = [...prevData.articleContent];
      updatedContent[index].articleStep = editorState;
      return {
        ...prevData,
        articleContent: updatedContent,
      };
    });
  };

  const handleAddStep = () => {
    setFormData((prevData) => ({
      ...prevData,
      articleContent: [
        ...prevData.articleContent,
        { articleStep: EditorState.createEmpty(), articleScreenshots: [] },
      ],
    }));
  };

  const handleRemoveStep = (index: number) => {
    const newArticleContent = formData.articleContent.filter(
      (_, i) => i !== index
    );
    setFormData((prevData) => ({
      ...prevData,
      articleContent: newArticleContent,
    }));
  };

  const handleScreenshotChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      const base64Promises = files.map((file) => {
        const reader = new FileReader();
        return new Promise<string>((resolve, reject) => {
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      Promise.all(base64Promises)
        .then((base64Images) => {
          setFormData((prevData) => {
            const updatedContent = [...prevData.articleContent];
            updatedContent[index].articleScreenshots = [
              ...updatedContent[index].articleScreenshots,
              ...base64Images,
            ];
            return {
              ...prevData,
              articleContent: updatedContent,
            };
          });
        })
        .catch((error) => {
          console.error("Error converting files to base64:", error);
        });
    }
  };

  const handleRemoveScreenshot = (
    stepIndex: number,
    screenshotIndex: number
  ) => {
    setFormData((prevData) => {
      const updatedContent = [...prevData.articleContent];
      const updatedScreenshots = updatedContent[
        stepIndex
      ].articleScreenshots.filter((_, i) => i !== screenshotIndex);
      updatedContent[stepIndex].articleScreenshots = updatedScreenshots;
      return {
        ...prevData,
        articleContent: updatedContent,
      };
    });
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const updatedArticleData = {
      _id: id,
      title: formData.title,
      description: formData.description,
      icon: formData.icon,
      isPublished: formData.isPublished,
      repositoryUrl: formData.repositoryUrl,
      enableFeedback: formData.enableFeedback,
      articleContent: formData.articleContent.map((step) => ({
        articleStep: convertToRaw(step.articleStep.getCurrentContent()),
        articleScreenshots: step.articleScreenshots,
      })),
    };

    await dispatch(editArticle(updatedArticleData));
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" align="center" gutterBottom>
        Edit Article
      </Typography>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
        onSubmit={handleSubmit}
      >
        <TextField
          id="title"
          label="Title"
          variant="outlined"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          required
        />
        <TextField
          id="description"
          label="Description"
          variant="outlined"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          multiline
          rows={4}
          required
        />
        <TextField
          id="icon"
          label="Icon URL"
          variant="outlined"
          name="icon"
          value={formData.icon}
          onChange={handleInputChange}
        />
        <TextField
          id="repositoryUrl"
          label="Repository URL"
          variant="outlined"
          name="repositoryUrl"
          value={formData.repositoryUrl}
          onChange={handleInputChange}
        />
        <FormControlLabel
          control={
            <Switch
              checked={formData.isPublished}
              onChange={handleSwitchChange}
              name="isPublished"
            />
          }
          label="Is Published"
        />
        <FormControlLabel
          control={
            <Switch
              checked={formData.enableFeedback}
              onChange={handleSwitchChange}
              name="enableFeedback"
            />
          }
          label="Enable Feedback"
        />
        {formData.articleContent.map((step, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <DraftEditor
              editorState={step.articleStep}
              setEditorState={(editorState) =>
                handleEditorChange(index, editorState)
              }
            />
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleRemoveStep(index)}
              sx={{ mt: 1 }}
            >
              Remove Step
            </Button>
            <Box>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={(e) => handleScreenshotChange(e, index)}
                style={{ marginTop: "16px" }}
              />
              {step.articleScreenshots.map((screenshot, sIndex) => (
                <Box
                  key={sIndex}
                  sx={{ mt: 1, display: "flex", alignItems: "center" }}
                >
                  <img
                    src={screenshot}
                    alt={`Screenshot ${sIndex + 1}`}
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      marginRight: "8px",
                    }}
                  />
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleRemoveScreenshot(index, sIndex)}
                  >
                    Remove
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleAddStep}
          sx={{ mb: 2, width: 200 }}
          size="small"
        >
          Add Step
        </Button>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Save Changes
        </Button>
      </Box>
      <Notify alertDisplay={alertDisplay} setAlertDisplay={setAlertDisplay} />
    </Container>
  );
};

export default AdminArticleEdit;

import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import {
  Button,
  Container,
  Typography,
  TextField,
  Box,
  Grid,
  InputLabel,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { adminLogin } from "../../_actions/adminActions";
import { RootState, AppDispatch } from "../../store";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import profile2 from "../../assets/profile2.jpg";

const AdminSignIn = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const adminState = useSelector((state: RootState) => state.admin);
  const { adminLoggedIn } = adminState;

  const [wrongCredentials, setWrongCredentials] = useState(false);

  const [formData, setFormData] = useState({
    userEmail: "",
    userPassword: "",
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const loginUser = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const loginData = {
      email: formData.userEmail,
      password: formData.userPassword,
    };
    await dispatch(adminLogin(loginData));
  };

  useEffect(() => {
    if (adminLoggedIn && !!localStorage.getItem("token")) {
      navigate("/admin/dashboard");
    } else if (adminLoggedIn && adminLoggedIn.status === 400) {
      setWrongCredentials(true);
      setTimeout(() => {
        setWrongCredentials(false);
      }, 5000);
    }
  }, [adminLoggedIn]);
  const theme = useTheme();

  return (
    <Container
      maxWidth="xl"
      disableGutters={true}
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              height: "auto",
              width: "80%",
              justifyContent: "space-evenly",
            }}
            autoComplete="off"
            onSubmit={loginUser}
          >
            <Typography
              variant="overline"
              color={theme.palette.secondary.main}
              sx={{
                fontSize: "1rem",
                fontWeight: 500,
                margin: 0,
                lineHeight: 2,
              }}
            >
              Login
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontSize: "1.8219rem",
                fontWeight: 700,
                margin: 0,
                lineHeight: 1,
              }}
            >
              Welcome back
            </Typography>
            <Typography
              variant="subtitle1"
              color={theme.palette.secondary.main}
              sx={{ fontSize: "1rem", fontWeight: 400, margin: 0 }}
            >
              Login to manage your account
            </Typography>

            <InputLabel
              htmlFor="outlined-email"
              sx={{ marginTop: 4, color: theme.palette.secondary.dark }}
            >
              Enter your email
            </InputLabel>
            <TextField
              required
              id="outlined-email"
              label="User Name"
              type="email"
              name="userEmail"
              variant="outlined"
              value={formData.userEmail}
              onChange={handleInputChange}
              fullWidth
              error={wrongCredentials}
              sx={{
                marginTop: 2,
              }}
            />
            <InputLabel
              htmlFor="outlined-password"
              sx={{ marginTop: 4, color: theme.palette.secondary.dark }}
            >
              Email your password
            </InputLabel>

            <TextField
              required
              id="outlined-password"
              label="Password"
              type="password"
              name="userPassword"
              variant="outlined"
              value={formData.userPassword}
              onChange={handleInputChange}
              fullWidth
              error={wrongCredentials}
              sx={{ marginTop: 2 }}
              helperText={wrongCredentials && "Incorrect email or password"}
            />
            <Button
              variant="contained"
              type="submit"
              size="large"
              sx={{ marginTop: 4, alignSelf: "end", width: 150 }}
            >
              Login
            </Button>
          </Box>
        </Grid>

        <Grid
          item
          lg={6}
          xl={6}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "block",
              xl: "block",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              backgroundImage: `url(${profile2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "100vh",
              clipPath: "polygon(10% 0%, 100% 0px, 100% 100%, 0% 100%)",
              shapeOutside: "polygon(10% 0%, 100% 0px, 100% 100%, 0% 100%)",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: "white",
                  textAlign: "center",
                  zIndex: 1,
                }}
              >
                Welcome to the Admin Panel
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminSignIn;

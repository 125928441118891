import {
  Typography,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import moment from "moment";
import EmptyData from "../ui-components/EmptyData/EmptyData";

import server_down from "../../assets/illustrations/server_down.svg";
import CircularLoader from "../ui-components/CircularLoader/CircularLoader";

interface Article {
  _id: number;
  title: string;
  description: string;
  icon: string;
  createdDate: string;
  modifiedDate: string;
}

interface HighlightsProps {
  listOfArticles: Article[];
  loading: boolean;
  error: any;
  handleOpenArticle: (id: number) => void;
}

const Highlights: React.FC<HighlightsProps> = ({
  listOfArticles,
  handleOpenArticle,
  loading,
  error,
}) => {
  console.log("listOfArticles::", listOfArticles);
  console.log("loading::", loading);
  console.log("error::", error);
  return (
    <>
      <Typography variant="h5">Highlights</Typography>

      {loading && <CircularLoader customHeight={250} />}
      {error && (
        <EmptyData
          fileName={server_down}
          title={"Something went wrong"}
          customHeight="auto"
        />
      )}
      {listOfArticles && listOfArticles.length > 0 && (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={3}
          sx={{ marginTop: 1 }}
        >
          {listOfArticles.map((item, index) => (
            <Grid key={index} item lg={6} md={6} sm={12}>
              <ListItem
                alignItems="flex-start"
                onClick={() => handleOpenArticle(item._id)}
              >
                <ListItemAvatar sx={{ marginTop: "unset" }}>
                  <img
                    alt="blog logo"
                    src={
                      item.icon
                        ? item.icon
                        : "https://www.svgrepo.com/show/261945/web-development.svg"
                    }
                    style={{ height: 70 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  sx={{ margin: "0px 20px", alignSelf: "center" }}
                  primary={
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {moment(item.createdDate).format("LLL")}
                    </Typography>
                  }
                  secondary={<Typography variant="h6">{item.title}</Typography>}
                />
              </ListItem>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default Highlights;

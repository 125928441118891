import React, { useState } from "react";

import { Alert, Snackbar } from "@mui/material";

interface NotifyProps {
  alertDisplay?: any;
  setAlertDisplay?: any;
}

const Notify: React.FC<NotifyProps> = ({ alertDisplay, setAlertDisplay }) => {
  const { open, alertColor, alertMsg } = alertDisplay;

  const hideAlert = () => {
    setAlertDisplay((prev: any) => ({
      ...prev,
      open: false,
      alertColor: undefined,
      alertMsg: "",
    }));
  };

  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={hideAlert}>
      <Alert
        onClose={hideAlert}
        severity={alertColor}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {alertMsg}
      </Alert>
    </Snackbar>
  );
};

export default Notify;

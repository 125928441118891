// src/types.ts
export const ADMIN_SIGN_IN = "ADMIN_SIGN_IN";

export const GET_ARTICLE_BY_ID = "GET_ARTICLE_BY_ID";
export const ADD_ARTICLE = "ADD_ARTICLE";
export const EDIT_ARTICLE = "EDIT_ARTICLE";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const LIST_OF_ARTICLES = "LIST_OF_ARTICLES";
export const CLEAR_ARTICLE_DATA = "CLEAR_ARTICLE_DATA";

export const FETCH_PHOTOS = "FETCH_PHOTOS";
export const GET_UNSPLASH_PROFILE = "GET_UNSPLASH_PROFILE";
export const GET_LIKES = "GET_LIKES";
export const UPDATE_LIKES = "UPDATE_LIKES";

export interface AdminAction {
  type: typeof ADMIN_SIGN_IN;
  payload?: any;
}

export interface ArticleAction {
  type:
    | typeof GET_ARTICLE_BY_ID
    | typeof ADD_ARTICLE
    | typeof LIST_OF_ARTICLES
    | typeof EDIT_ARTICLE
    | typeof DELETE_ARTICLE
    | typeof CLEAR_ARTICLE_DATA;
  status: "loading"|"request" | "success" | "failure";
  payload?: any;
}

export interface PublicAction {
  type:
    | typeof FETCH_PHOTOS
    | typeof GET_UNSPLASH_PROFILE
    | typeof GET_LIKES
    | typeof UPDATE_LIKES;
  payload?: any;
}
